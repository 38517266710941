export const heroObj = {
  lightText: true,
  lightTextDesc: true,
  headline: 'Calicamo Financial Services', 
  description: 'Insurance made easy.',
  primary: false, 
  buttonLabel: ''
};

export const homeObjOne = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Why pick Calicamo?',
  headline: 'When life happens, we’re there for you around the clock.',
  description:
    "And with a team of agents, feel free to talk with someone in your community.",
  buttonLabel: '',
  imgStart: '',
  img: require('../../images/3.jpg'),
  alt: 'Vault',
  start: ''
};

export const homeObjTwo = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Secure Your Rate Today',
  headline: 'Contact one of our agents',
  description:
    'You will never have to worry about over paying for insurance. Our agency strives to find an appropriate coverage to meets your needs.',
  buttonLabel: '',
  imgStart: 'start',
  img: require('../../images/desk-580.jpg'),
  alt: 'Vault',
  start: 'true',
  buttonLink: '/contact'
};
