export const aboutObjOne = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Contact Calicamo. We’re here to assist',
    description:
        ['Get in touch to start building your financial future together.'],
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/2.jpg'),
    alt: '',
    start: true, 
    buttonLink: ""
};