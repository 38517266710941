import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';
import { aboutObjOne } from "./Data";
import './Contact.css';

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact - Calicamo Financial Services</title>
                <meta
                    charSet="utf-8"
                    name="description"
                    content="Calicamo Financial Services ensures its agency puts customer service first."
                />
                <link rel="canonical" href="https://calicamofinancials.com" />
            </Helmet>
            <InfoSection {...aboutObjOne} />
            <div className="contact-container">
                <div className="contact-text">
                    <div className="contact-info">
                        <p className="contact-statement">
                            Give us a call at <br />
                            <a href="tel:13607467197">1-360-746-7197</a>
                        </p>
                        <p className="contact-statement">
                            E-mail us at <br />
                            <a href="mailto:calicamofinancials@outlook.com">calicamofinancials@outlook.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact
