import React from "react";
import { Button } from '../../globalStyles';
import {
  HeroContainer,
  Heading,
  Subtitle,
  BookingContainer
} from "./HeroSection.elements";

function HeroSection({
  lightText,
  lightTextDesc,
  headline,
  description,
  primary,
  buttonLabel
})
 
{
  return (
    <>
      <HeroContainer>
          <BookingContainer>
            <Heading lightText={lightText}>{headline}</Heading>
            <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
              
          </BookingContainer>
      </HeroContainer>
    </>
  );
}

export default HeroSection;
