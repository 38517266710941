import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';

function About() {


    const aboutObjOne = {
        primary: false,
        lightBg: true,
        lightTopLine: false,
        lightText: false,
        lightTextDesc: false,
        topLine: 'Competitive Rates and Proven Expertise',
        headline: 'Unlock Your Financial Future with Calicamo Financials - 20 Years of Expert Guidance',
        description:
            [`Welcome to Calicamo Financials. We’re a dedicated team with a mission to make your finances work for you. With over 20 years of combined experience, our goal is to make financial planning straightforward and manageable.

We understand that navigating financial decisions can feel overwhelming, but our mission is to simplify the process. Whether you’re looking into life insurance, planning for long-term savings, preparing for retirement, or considering commercial insurance bonds and auto insurance, our team is here to support you with a plan tailored to your unique needs and goals.

Think of us as trusted partners on your financial journey, offering clear and practical advice without the jargon. Together, we’ll work toward a secure and prosperous future, one step at a time.

If you’re ready to take control of your financial future, let’s connect. We’re here to help you build a future that truly works for you.`],
        buttonLabel: '',
        imgStart: 'false',
        img: require('../../images/1.jpg'),
        alt: 'Vault',
        start: 'true'
    };

    return (
        <>
        <Helmet>
        <title>About - Calicamo Financial Services</title>
        <meta
          charSet="utf-8"
          name="description"
          content="Calicamo Financial Services ensures its agency puts customer service first."
        />
        <link rel="canonical" href="https://calicamofinancials.com"/>
        </Helmet>
            <InfoSection {...aboutObjOne} />
        </>
    );
}

export default About
