import React from "react";
import { heroObj, homeObjOne, homeObjTwo } from "./Data";
import {
  HeroSection,
  InfoSection,
} from "../../components";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Home - Calicamo Financial Services</title>
        <meta
          charSet="utf-8"
          name="description"
          content="Calicamo Financial Services ensures its agency puts customer service first."
        />
        <link rel="canonical" href="https://calicamofinancials.com"/>
      </Helmet>
      <HeroSection {...heroObj} />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
    </>
  );
}

export default Home;
