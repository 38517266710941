import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import img from "./../../images/cal.jpg";

const fadeInAnimation = keyframes`${fadeIn}`;

export const HeroContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 991px) {
  }
`;

export const BookingContainer = styled.div`
  background: url(${img});
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: scroll;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const VideoHeroSec = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /* background: url(placeholder.jpg) no-repeat; */
  background-size: cover;
`;

export const FadeInImage = styled.div`
  animation: 1.75s ${fadeInAnimation};
`;

export const Heading = styled.h1`
  margin: 0 auto;
  margin-top: 300px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};
  /*Required because of Slider component
  margin-left: 700px;*/

  @media screen and (max-width: 960px) {
    /*Required because of Slider component
    margin-left: 52px;*/
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
  /*Required because of Slider component
  margin-left: 700px;*/
`;

export const HeroButton = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#434343" : "#B40606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  /*Required because of Slider component*/
  margin-left: 692px;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#B40606" : "#434343")};
    color: ${({ primary }) => (primary ? "#000000" : "#000000")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    /*Required because of Slider component*/
    margin-left: 0;
  }
`;
